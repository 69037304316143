import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import Video from '@tovia/man-ui/lib/components/Video/Video';
import RoundButton from '@tovia/man-ui/lib/components/Button/RoundButton';
import FreeGalleriesMoviesForm from '../../../components/forms/FreeGalleriesMoviesForm';
import { freeGalleriesMoviesSignup, reset } from 'src/client/redux/modules/marketing';
import redirectHomepages from 'src/shared/constants/redirectHomepages';
import { useSelector, useSettingsSelector } from '../../../redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { useInfinityTagEvent } from '../../../helpers/useInfinityTagEvents';
import { SplashFreeGallery, VideoLoopResponse } from 'src/@types/Splash';

const Div = styled.div<{
  isEU?: boolean;
}>`
  max-height: 585px;
  padding-top: 0;
  // background: url('/images/responsive/slider/movie_backgroundOverlay_light.png') no-repeat center;
  background-size: cover;
  // color: ${(props) => props.theme.primary3};
  margin-bottom: 12px;

  ::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1001;
    content: '';
  }

  @media (max-width: 767px) {
    padding: 20px 0 10px;
  }

  & .email-signup-container {
    padding-top: 25px;

    .row.response-wrap {
      position: relative;
      z-index: 1;
      padding-bottom: 10px;
    }
  }

  & .video-wrapper {
    position: fixed;
    z-index: -1;
    overflow: hidden;
    top: 60px;
    width: 100%;
  }

  & .sex-background {
    height: 600px;
    width: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 1200px) {
      height: 720px;
      top: -120px;
    }

    img {
      width: 100%;
    }
  }

  ${(props) =>
    props.isEU &&
    css`
      &.email-signup-section {
        max-height: unset;
        min-height: 585px;
      }

      & .sex-background {
        height: 100vh;
        width: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: 0;

        @media (min-width: 568px) {
          height: 600px;
        }

        @media (min-width: 1200px) {
          height: 775px;
        }
      }
    `}

  & .email-signup-header {
    font-size: 2.15rem;
    white-space: nowrap;
    position: relative;
    z-index: 1;

    @media (max-width: 639px) {
      font-size: 1.85rem;
      white-space: normal;
    }
  }

  & .signup-response-text {
    font-size: 1.05rem;
  }

  & .email-signup-container-locked {
    position: absolute;
    height: 40px;
    width: 40px;
    right: 24px;
    bottom: 2px;
    display: block;
  }

  & .free-movie-cover {
    position: relative;
    display: inline-block;
  }

  & .img-container {
    position: relative;
    margin: 0 25px;

    @media (max-width: 639px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    @media (max-width: 479px) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  & .custom-img-form {
    height: 150px;
    box-shadow: 0 0 400px 55px #000;

    @media (max-width: 639px) {
      height: 105px;
      width: auto;
    }
  }

  & .mx-highlight {
    font-size: 1.8rem;
    border-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-color: #000;
    margin: 2px 40px;
    padding: 2px 0;

    @media (max-width: 767px) {
      font-size: 0.95rem;
    }
  }

  & .email-signup-skip {
    padding-bottom: 12px;
  }

  & .email-signup-checkbox-row {
    margin: 5px 0 10px 16px;
    min-height: 20px;
    font-weight: 100;
    font-size: 0.85rem;
    letter-spacing: 0.1em;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  }
`;

const StyledLockIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 2.5rem;
  bottom: 0;
  right: 0;
`;

const StraplezDiv = styled.div<{ backgroundImg: string; isEU?: boolean }>`
  height: 720px;
  padding-top: 0;
  color: #fff;
  background-image: url(${(props) => props.backgroundImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  position: relative;

  ::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1001;
    content: '';
  }

  & .email-signup-container {
    padding-top: 5%;

    .row.response-wrap {
      position: relative;
      z-index: 1;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 10px;
    height: 400px;
  }

  @media (max-width: 414px) {
    height: 320px;
  }

  ${(props) =>
    props.isEU &&
    css`
      &.email-signup-section {
        max-height: unset;
        min-height: 585px;
      }
    `}

  & .email-signup-header {
    color: #fff;
    font-size: 64px;
    font-family: Poppins, 'Open Sans';
    font-weight: 700;
    white-space: nowrap;
    position: relative;
    z-index: 1;

    @media (max-width: 639px) {
      font-size: 1.85rem;
      white-space: normal;
    }
  }

  & .signup-response-text {
    font-size: 1.05rem;
  }

  & .email-signup-container-locked {
    position: absolute;
    height: 40px;
    width: 40px;
    right: 24px;
    bottom: 2px;
    display: block;
  }

  & .free-movie-cover {
    position: relative;
    display: inline-block;
  }

  & .img-container {
    position: relative;
    margin: 0 25px;

    @media (max-width: 639px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    @media (max-width: 479px) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  & .custom-img-form {
    height: 150px;

    @media (max-width: 639px) {
      height: 100px;
      width: auto;
    }
  }

  & .mx-highlight {
    font-size: 20px;
    margin: 20px auto 40px;
    font-weight: 400;

    .highlight {
      color: #17c975;
    }

    @media (max-width: 768px) {
      font-size: 1.05rem;
      margin: 0 auto 20px;
    }

    @media (max-width: 414px) {
      font-size: 0.85rem;
    }
  }

  & .email-signup-skip {
    margin-top: 50px;
    padding-bottom: 12px;
    @media (max-width: 768px) {
      margin-top: 33px;
    }
  }

  & .email-signup-checkbox-row {
    font-family: Poppins, 'Open Sans';
    color: #fff;
    margin: 5px 0 10px 16px;
    min-height: 20px;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  }

  & .lock {
    position: absolute;
    font-size: 2.85rem;
    bottom: 0;
    right: 0;
  }

  .straplez {
    .email-signup-submit-btn {
      height: 80px;
      font-size: 32px;
      font-weight: 600;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 18px;
        height: 60px;
        line-height: 49px;
      }
      @media (max-width: 639px) {
        font-size: 18px;
        height: 60px;
        line-height: 49px;
      }
      @media (max-width: 414px) {
        height: 55px;
        line-height: 40px;
      }
    }
    .email-signup-input {
      width: 100%;
      border-radius: 3px;
      margin: 0 auto;
      height: 60px;
      line-height: 60px;
      font-size: 24px;

      @media (max-width: 414px) {
        font-size: 1.15rem;
      }

      :focus::-webkit-input-placeholder {
        color: transparent;
      }
    }
    .email-signup-form-box {
      padding: 0 4%;
    }
  }
`;

const CloseSlider = styled.div`
  position: absolute;
  top: 0;
  right: 27px;
  width: 60px;
  height: 60px;
  font-size: 60px;
  @media (max-width: 1024px) {
    font-size: 32px;
    top: 0;
    right: -20px;
  }
  :hover {
    cursor: pointer;
  }
`;

export default function EmailSignupSection({
  videoLoop,
  freeGalleries,
  show = true,
  isStraplez = false,
  openSlider,
}: {
  videoLoop?: VideoLoopResponse;
  freeGalleries: SplashFreeGallery[];
  show?: boolean;
  isStraplez?: boolean;
  openSlider?: () => void;
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const intl = useIntl();
  const config = useSelector((state) => state.app.config);
  const { contentCdnUrl, cdnUrl } = useSelector((state) => state.app);
  const { isEU } = useSelector((state) => state.auth.location);
  const site = useSelector((state) => state.site);

  const { freeGalleriesMoviesStatus: callStatus, freeGalleriesMoviesError: error } = useSelector(
    (state) => state.marketing,
  );
  const mainPageUrl = useSettingsSelector('homepage');

  const sendSkipButtonEventOnClick = useInfinityTagEvent({
    name: 'Skip to the main page button',
    type: 'Inquiry',
    value: '0.001',
  });

  const [showDialog, setDialogVisibility] = useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');

  let freeSiteUUID = destructUUID(site.UUID);
  if (config.isWhiteLabel && config.compilation.params.siteUUIDs && config.compilation.params.siteUUIDs.length) {
    [freeSiteUUID] = config.compilation.params.siteUUIDs;
  }

  const getSplashVideo = useCallback(
    (ext) => {
      if (videoLoop) {
        return `${contentCdnUrl}/${freeSiteUUID}/media/splash/videos/${videoLoop.UUID}.${ext}`;
      }
      return undefined;
    },
    [contentCdnUrl, freeSiteUUID, videoLoop],
  );

  const generateVideoUrls = () => {
    if (!videoLoop) {
      return null;
    }

    return {
      ...(videoLoop.mp4 ? { mp4: getSplashVideo('mp4') } : {}),
      ...(videoLoop.ogg ? { ogg: getSplashVideo('ogg') } : {}),
      ...(videoLoop.webm ? { webm: getSplashVideo('webm') } : {}),
    };
  };

  const onSubmit = (values) => {
    setSubmittedEmail(values.email);
    if (!showDialog && isEU && !values.optin) {
      setDialogVisibility(true);
    } else {
      dispatch(
        freeGalleriesMoviesSignup({
          ...values,
        }),
      );
      setDialogVisibility(false);
    }
  };

  const renderFreeMovieCovers = () => {
    const freeMovies = freeGalleries.map(({ galleryUUID, name, siteUUID }) => ({
      galleryUUID,
      name,
      coverUrl: `${contentCdnUrl}/${destructUUID(siteUUID)}/media/${destructUUID(galleryUUID)}/cover_${destructUUID(
        galleryUUID,
      )}.jpg`,
    }));

    return freeMovies.map((item) => (
      <div className="free-movie-cover" key={item.galleryUUID}>
        <div>
          <div className="img-container">
            <StyledLockIcon icon={faLockAlt} />
            <img src={item.coverUrl} alt={item.name} className="img-responsive custom-img-form" />
          </div>
        </div>
      </div>
    ));
  };

  // This is some hacked ghetto code.  Hopefully it gets removed before anyone
  // else has to see it or extend it.
  const freeContentMessage =
    freeGalleries.length === 4
      ? intl.formatMessage({ id: 'fourFreeGalleries', defaultMessage: 'SIGN UP AND GET FOUR FREE GALLERIES' })
      : intl.formatMessage({ id: 'threeFilmsForFree', defaultMessage: 'SIGN UP TO WATCH THREE FILMS FOR FREE' });

  const customError = error
    ? intl.formatMessage({
        id: 'splash.emailSignupSection.errorMesssage',
        defaultMessage: 'There was a problem submitting your request, please try again later',
      })
    : undefined;

  const getHighlightedMessage = () => {
    let freeGalleriesCount = 'These';

    switch (freeGalleries.length) {
      case 3:
        freeGalleriesCount = 'Three';
        break;
      case 4:
        freeGalleriesCount = 'Four';
        break;
      default:
        break;
    }

    return freeGalleries[0].galleryType === 'MOVIE'
      ? `Watch ${freeGalleriesCount} Full Length Films`
      : `View ${freeGalleriesCount} Complete Galleries`;
  };

  if (!show) return null;

  if (isStraplez) {
    return (
      <StraplezDiv
        isEU={isEU}
        className="email-signup-section"
        backgroundImg={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/${site.abbreviation}_free_galleries_bg.jpg`}
      >
        {(!(callStatus === 'success') && (
          <div className="container email-signup-container">
            <CloseSlider onClick={openSlider}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </CloseSlider>
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="email-signup-header text-center">
                  Want to see more?
                  <div className="mx-highlight">
                    Sign Up Now to <span className="highlight">{getHighlightedMessage()}</span> for{' '}
                    <span className="highlight">FREE</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
                <FreeGalleriesMoviesForm
                  isEU={isEU}
                  isStraplez={isStraplez}
                  showOptInDialog={showDialog}
                  form="freeGalleriesForm"
                  callStatus={callStatus}
                  onSubmit={onSubmit}
                  submitError={customError}
                  className={'straplez'}
                />
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-8 col-md-offset-2 email-signup-skip">
                <div className="row email-signup-checkbox-row">
                  <div className="col-xs-12">
                    {intl.formatMessage({
                      id: 'splash.emailSignupSection.creditCardRequirements',
                      defaultMessage: 'Credit card not required. No obligation.',
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )) || (
          <div className="container email-signup-container">
            <div id="signup-panel" className="container email-signup-container">
              <div className="row response-wrap">
                <div className="col-md-8 col-md-offset-2">
                  <div className="signup-response-text text-center">
                    {intl.formatMessage(
                      {
                        id: 'splash.emailSignupSection.response.p1',
                        defaultMessage: `Please go to your email ${submittedEmail}, and click the confirmation link you'll find inside.`,
                      },
                      { submittedEmail },
                    )}
                  </div>
                </div>
              </div>
              <div className="row response-wrap">
                <div className="col-md-8 col-md-offset-2">
                  <div className="signup-response-text text-center">
                    {intl.formatMessage({
                      id: 'splash.emailSignupSection.response.p2',
                      defaultMessage: 'Please note to check your spam folder if you do not receive the email from us.',
                    })}
                  </div>
                </div>
              </div>
              <div className="row response-wrap">
                <div className="col-md-8 col-md-offset-2">
                  <div className="signup-response-text text-center">
                    {intl.formatMessage({
                      id: 'splash.emailSignupSection.response.p3',
                      defaultMessage: 'You’re unlocking the following full-length films.',
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <div className="v-offset-15 text-center">
                    <div className="list-group">{renderFreeMovieCovers()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </StraplezDiv>
    );
  } else {
    return (
      <Div isEU={isEU} className="email-signup-section">
        <div className="video-wrapper">
          {generateVideoUrls() && (
            <Video
              className="sex-background"
              {...generateVideoUrls()}
              imgAlt={site.name}
              autoPlay
              loop
              width="inherit"
              height="inherit"
              muted
              playsInline
            />
          )}
        </div>
        {(!(callStatus === 'success') && (
          <div className="container email-signup-container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="email-signup-header text-center">
                  {intl.formatMessage({ id: 'splash.emailSignupSection.seeMore', defaultMessage: 'WANT TO SEE MORE?' })}
                  <br />
                  <div className="mx-highlight">{freeContentMessage}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="v-offset-15 text-center">
                  <div className="list-group">{renderFreeMovieCovers()}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
                <FreeGalleriesMoviesForm
                  isEU={isEU}
                  showOptInDialog={showDialog}
                  form="freeGalleriesForm"
                  callStatus={callStatus}
                  onSubmit={onSubmit}
                  submitError={customError}
                />
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-8 col-md-offset-2 email-signup-skip">
                <div className="row email-signup-checkbox-row">
                  <div className="col-xs-12">
                    {intl.formatMessage({
                      id: 'splash.emailSignupSection.creditCardRequirements',
                      defaultMessage: 'Credit card not required. No obligation.',
                    })}
                  </div>
                </div>
                <div className="row" onClick={sendSkipButtonEventOnClick}>
                  <RoundButton to={redirectHomepages[mainPageUrl]}>
                    {intl.formatMessage({
                      id: 'splash.emailSignupSection.button',
                      defaultMessage: 'SKIP TO MAIN PAGE',
                    })}
                  </RoundButton>
                </div>
              </div>
            </div>
          </div>
        )) || (
          <div className="container email-signup-container">
            <div id="signup-panel" className="container email-signup-container">
              <div className="row response-wrap">
                <div className="col-md-8 col-md-offset-2">
                  <div className="signup-response-text text-center">
                    {intl.formatMessage(
                      {
                        id: 'splash.emailSignupSection.response.p1',
                        defaultMessage: `Please go to your email ${submittedEmail}, and click the confirmation link you'll find inside.`,
                      },
                      { submittedEmail },
                    )}
                  </div>
                </div>
              </div>
              <div className="row response-wrap">
                <div className="col-md-8 col-md-offset-2">
                  <div className="signup-response-text text-center">
                    {intl.formatMessage({
                      id: 'splash.emailSignupSection.response.p2',
                      defaultMessage: 'Please note to check your spam folder if you do not receive the email from us.',
                    })}
                  </div>
                </div>
              </div>
              <div className="row response-wrap">
                <div className="col-md-8 col-md-offset-2">
                  <div className="signup-response-text text-center">
                    {intl.formatMessage({
                      id: 'splash.emailSignupSection.response.p3',
                      defaultMessage: 'You’re unlocking the following full sets.',
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <div className="v-offset-15 text-center">
                    <div className="list-group">{renderFreeMovieCovers()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Div>
    );
  }
}
