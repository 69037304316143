import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { FeatureFlags } from 'src/@types/SiteConfig';
import { useSelector } from '../redux';

export const useFeatureFlags = (): FeatureFlags => {
  const siteConfig = useSelector((state) => state.app.config);
  return siteConfig?.featureFlags || {};
};

/**
 * @returns True if the enableWideModelPage feature flag is true the current route is /model/*
 */
export const useWidePage = (): boolean => {
  const { enableWideModelPage = false } = useFeatureFlags();
  const user = useSelector((state) => state.auth.user);
  const { pathname } = useLocation();

  // This is going to get worse before it gets better...
  const modelPageMatch = useMemo(
    () => pathname.startsWith('/model/') && !pathname.includes('/gallery/') && !pathname.includes('/movie/'),
    [pathname],
  );

  const modelsPageMatch = useMemo(() => pathname.startsWith('/models'), [pathname]);
  const galleriesPageMatch = useMemo(() => pathname.startsWith('/galleries'), [pathname]);
  const imagesPageMatch = useMemo(() => pathname.startsWith('/images'), [pathname]);
  const photoArchivePageMatch = useMemo(() => pathname.startsWith('/photo-archive'), [pathname]);
  const photographersPageMatch = useMemo(() => pathname.startsWith('/photographers'), [pathname]);
  const moviesPageMatch = useMemo(() => pathname.startsWith('/erotic-films'), [pathname]);

  const hasPageMatch = [
    galleriesPageMatch,
    imagesPageMatch,
    photoArchivePageMatch,
    modelPageMatch,
    modelsPageMatch,
    photographersPageMatch,
    moviesPageMatch,
  ].some(Boolean);

  const showWidePage = Boolean(hasPageMatch && enableWideModelPage && !user);

  return showWidePage;
};
