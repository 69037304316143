import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { Panel, Badges, Badge, TABLET_PORTRAIT } from '@tovia/man-ui';
import RatingSlider from '@tovia/man-ui/lib/components/RatingSlider/RatingSlider';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faEye, faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import { faComments, faMicrophoneAlt } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';
import PanelHeadline from '@tovia/man-ui/lib/components/Panel/PanelHeadline';
import { formatViews } from 'src/client/utils/converters';
import { rateItem } from 'src/client/redux/modules/ratingInfo';
import { show as showShareDialogFn } from 'src/client/redux/modules/shareDialog';
import { Panel as ActivityPanelPlaceholder } from 'src/client/components/Placeholders/Panels/Panel';
import { scrollTo } from 'src/client/helpers';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { redirectToJoin, useJoinUrlGenerator } from 'src/client/components/buttons/JoinButton';

const PanelWrapper = styled.div`
  .rating-slider {
    top: 32px;
    margin: 0 45px;
    max-width: 238px;
    margin-bottom: 43px;
  }

  .favorite-badge {
    &.favorited {
      path {
        fill: #a80000;
      }
    }
  }
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Rating = styled.div`
  margin-right: 10px;
`;

const RatingHeader = styled.span`
  display: block;
  font-size: 1.2rem;
  margin-top: 3px;
`;

const RatingSubHeader = styled.span`
  display: block;
  font-size: 0.75rem;
  color: ${(props) => props.theme.primary7};
`;

const RatingAverage = styled.span`
  width: 48px;
  height: 48px;
  line-height: 42px;
  border-width: 3px;
  border-style: solid;
  border-color: ${(props) => props.theme.primaryBtn1Bg};
  border-radius: 50%;
  display: block;
  //font-size: 1rem;
  text-align: center;
`;

type Props = {
  blogUrl?: string | null;
  className?: string;
  condensedView?: boolean;
  commentCount?: number;
  favoriteCount?: number;
  fullMode?: boolean;
  isFavorited?: boolean;
  itemUrl: string;
  objectType: string;
  objectUUID: string;
  onToggleFavor?: () => void;
  ratingAverage: number;
  ratings: number;
  views: number;
};

export const ActivityPanel = (props: Props) => {
  const dispatch = useDispatch();

  const ratingInfo = useSelector((state) => state.ratingInfo);
  const comments = useSelector((state) => state.comments.comments);
  const user = useSelector((state) => state.auth.user);
  const generateJoinUrl = useJoinUrlGenerator();

  const {
    blogUrl = '',
    className = '',
    condensedView = false,
    commentCount = 0,
    favoriteCount = null,
    fullMode = false,
    isFavorited,
    itemUrl,
    objectType,
    objectUUID,
    onToggleFavor = () => {},
    ratingAverage,
    ratings,
    views,
  } = props;

  const showShareDialog = (link) => dispatch(showShareDialogFn(link, `activity-panel-${objectType}`, objectUUID));
  const finalCommentsCount = comments[objectUUID] ? comments[objectUUID].commentsCount : commentCount;

  const onRate = useCallback(
    (rating) => {
      dispatch(rateItem({ objectUUID, objectType: objectType.toUpperCase(), rating }));
    },
    [objectType, objectUUID, dispatch],
  );

  const userRating = useMemo(() => ratingInfo.ratings[objectUUID], [ratingInfo.ratings, objectUUID]);

  const activityPanelContent = (
    <Panel className="top-panel">
      <StyledHeadline condensedView={condensedView}>Activity</StyledHeadline>
      <RatingContainer>
        <Rating>
          <RatingAverage>{ratingAverage || 'N/A'}</RatingAverage>
        </Rating>
        <div>
          <RatingHeader>Member Rating</RatingHeader>
          <RatingSubHeader>
            {ratingAverage > 0 && <span>{`based on ${formatViews(ratings)} ratings`}</span>}
            {!ratingAverage && <span>Be the first to rate!</span>}
          </RatingSubHeader>
        </div>
      </RatingContainer>

      <RatingSlider
        className="rating-slider"
        submitRating={user && fullMode ? onRate : () => redirectToJoin(generateJoinUrl, 'rate-activity-panel')}
        userRating={userRating}
      />
      {!condensedView && (
        <Badges>
          {favoriteCount !== null && (
            <Badge
              className={`favorite-badge ${fullMode && isFavorited ? ' favorited' : ''}`}
              onClick={onToggleFavor}
              icon={<FontAwesomeIcon icon={isFavorited ? faHeartSolid : faHeart} />}
              text={favoriteCount || 0}
            />
          )}
          <Badge text={formatViews(views)} icon={<FontAwesomeIcon icon={faEye} />} />
          {finalCommentsCount > 0 && (
            <Badge
              text={formatViews(finalCommentsCount)}
              icon={<FontAwesomeIcon icon={faComments} />}
              onClick={() => scrollTo('comment-section')}
            />
          )}
          {blogUrl && (
            <Link to={blogUrl}>
              <Badge text="Interview" icon={<FontAwesomeIcon icon={faMicrophoneAlt} />} />
            </Link>
          )}
          <a className="clickable share-button" onClick={() => showShareDialog(itemUrl)}>
            <Badge text="Share" icon={<FontAwesomeIcon icon={faShare} />} />
          </a>
        </Badges>
      )}
    </Panel>
  );

  const activityPanelMarkup =
    !user || ratingInfo.loaded ? activityPanelContent : <ActivityPanelPlaceholder width={100} />;

  return <PanelWrapper className={className}>{activityPanelMarkup}</PanelWrapper>;
};

const StyledHeadline = styled(PanelHeadline)<{ condensedView: boolean }>`
  ${(props) =>
    props.condensedView &&
    css`
      display: none;

      @media ${TABLET_PORTRAIT} {
        display: block;
      }
    `}
`;
