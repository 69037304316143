const ActionType = {
  SHOW: 'man-site/share-dialog/SHOW',
  HIDE: 'man-site/share-dialog/HIDE',
} as const;

type State = {
  isOpen: boolean;
  link: string;
  contentType: string;
  contentID: string;
};

type Show = State & {
  type: typeof ActionType.SHOW;
};

type Hide = {
  type: typeof ActionType.HIDE;
};

type ActionType = typeof ActionType[keyof typeof ActionType];

type Action = Show | Hide;

const initialState: State = {
  isOpen: false,
  link: '',
  contentType: '',
  contentID: '',
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.SHOW:
      return {
        ...state,
        isOpen: true,
        link: action.link,
        contentType: action.contentType,
        contentID: action.contentID,
      };
    case ActionType.HIDE:
      return {
        ...state,
        isOpen: false,
        link: '',
      };
    default:
      return state;
  }
}

export function show(link: string, contentType: string, contentID: string): Show {
  window.dataLayer.push({
    event: 'share-dialog',
    content_type: contentType,
    content_id: contentID,
  });

  return {
    type: ActionType.SHOW,
    link,
    contentType,
    contentID,
    isOpen: true,
  };
}

export function hide(): Hide {
  return {
    type: ActionType.HIDE,
  };
}
