import { Button, Panel } from '@tovia/man-ui';
import { Link } from 'react-router-dom';
import { StandardGrid } from 'src/client/components/grids';
import RankingDetailsPanel from 'src/client/components/panels/RankingDetailsPanel';
import {
  ConstrainedContainer,
  DESKTOP,
  // DESKTOP_WIDE,
  TABLET_LANDSCAPE,
  TABLET_PORTRAIT,
  XXXXX_LARGE,
  XXXX_LARGE,
  XXX_LARGE,
  XX_LARGE,
  X_LARGE,
} from 'src/client/constants/mediaQueries';
import styled from 'styled-components/macro';
// import { DetailedCardsWrapper } from '../../Cards/Detailed/DetailedCards';
import { ActivityPanel } from '../../Panels/ActivityPanel';

export const ANIMATION_NAME = 'model-bio';
export const ANIMATION_DURATION = 250;

export const WideModelWrapper = styled.div`
  overflow: hidden;
  background-color: ${(props) => props.theme.primary1};
  margin-top: 10px;
`;

export const WideModelLayout = styled(ConstrainedContainer)<{ showRanking: boolean }>`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  grid-template-areas:
    'info'
    'activity'
    'content';

  &.bio-toggled {
    grid-template-areas:
      'info'
      'bio'
      'activity'
      'content';
  }

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'info activity'
      'content content';

    &.bio-toggled {
      grid-template-areas:
        'info activity'
        'bio bio'
        'content content';
    }
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: 360px 1fr 1fr;
    grid-template-areas:
      ${(props) => `'info activity ${props.showRanking ? 'ranking' : 'activity'}'`}
      'content content content';

    &.bio-toggled {
      grid-template-areas:
        ${(props) => `'info activity ${props.showRanking ? 'ranking' : 'activity'}'`}
        'bio bio bio'
        'content content content';
    }
  }

  @media ${DESKTOP} {
    grid-template-columns: 360px 1fr;
    grid-template-areas:
      'info content'
      'activity content'
      'ranking content'
      'spacer content';

    &.bio-toggled {
      grid-template-areas:
        'info content'
        'bio content'
        'activity content'
        'ranking content'
        'spacer content';
    }
  }

  /* Add more grid columns to the models page grid */
  & ${StandardGrid}.wide-page {
    @media ${TABLET_LANDSCAPE} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media ${DESKTOP} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media ${X_LARGE} {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media ${XX_LARGE} {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @media ${XXX_LARGE} {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @media ${XXXX_LARGE} {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    @media ${XXXXX_LARGE} {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
`;

export const StyledActivityPanel = styled(ActivityPanel)`
  grid-area: activity;

  .panel {
    padding: 10px;
    margin-bottom: 0;

    @media ${TABLET_PORTRAIT} {
      height: 100%;
    }
  }

  .panel-content {
    padding: 0;
  }
`;

export const StyledRankingDetailsPanel = styled(RankingDetailsPanel)`
  display: none;

  @media ${TABLET_LANDSCAPE} {
    display: block;
  }

  .panel {
    padding: 10px;
    margin-bottom: 0;

    @media ${TABLET_PORTRAIT} {
      height: 100%;
    }
  }
`;

export const WideModelPageContent = styled.div`
  grid-area: content;
  overflow: hidden;

  & > div > h2 {
    margin: 5px 0 15px;
  }
`;

export const ModelBio = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 10px;

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: 360px 1fr;
  }

  @media ${DESKTOP} {
    grid-template-columns: 1fr;
  }
`;

export const Biography = styled(Panel)`
  padding: 10px;
  margin: 0;
  word-break: break-word;

  .panel-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const Attributes = styled(Panel)`
  padding: 10px;
  margin: 0;
`;

export const ModelBioTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

export const InfoPanelWrapper = styled.div`
  grid-area: info;
  height: min-content;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 10px;
  grid-template-rows: auto auto auto 1fr auto;
  grid-template-areas:
    'cover_image model_name'
    'cover_image model_country'
    'cover_image model_age'
    'cover_image model_social'
    'cover_image model_info'
    'model_rank bio_link';
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.primary3};
  padding: 10px;
`;

export const ModelName = styled.h1`
  grid-area: model_name;
  margin: 0;
  font-size: 22px;
  line-height: 24px;
`;

export const CoverImage = styled.img`
  grid-area: cover_image;
  width: 100px;
`;

export const ModelRank = styled.div`
  grid-area: model_rank;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primary1};
  padding: 5px 0;
`;

export const ModelCountry = styled(Link)`
  grid-area: model_country;
  font-size: 14px;
  line-height: 24px;
`;

export const ModelAge = styled.div`
  grid-area: model_age;
`;

export const ModelSocial = styled.div`
  grid-area: model_social;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 6px;

  .count-btn {
    margin: 0;
  }
`;

export const ModelInfo = styled.div`
  grid-area: model_info;
  display: flex;
  column-gap: 10px;
  align-items: center;
  height: 30px;
`;

export const BioLink = styled.div<{ bioToggled?: boolean }>`
  grid-area: bio_link;
  padding: 5px 0;
  cursor: pointer;
  user-select: none;
`;

export const BioComingSoon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  opacity: 0.5;
  font-style: italic;
`;

export const ShareButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary1};
  border-color: ${({ theme }) => theme.primary3};
  margin-left: auto;
`;
