import React from 'react';
import { OnPageContentBlock, OnPageTextProps } from 'src/client/components/OnPageSeo/OnPageText/OnPageText';
import { Route, Switch } from 'react-router';

const maHomepage: OnPageContentBlock = {
  text1: <h2>Fantasy to Reality: How Erotic Art by MetArt Transforms Your Viewing Experience</h2>,
  text2: (
    <div>
      Erotic art is distinguished by its ability to engage your senses on multiple levels. It’s not just about what you
      see but how you feel and experience the engaging content. At the forefront of this transformative experience is
      MetArt, a platform celebrated for its exceptional approach to erotic art. MetArt is not just a collection of
      erotic female photos; it is a unique blend of artistic and erotic content that transforms the viewer’s experience
      from mere fantasy to a deeply personal and immersive reality.
    </div>
  ),
};

export const OnPageTextContentMa = (props: OnPageTextProps) => {
  const { contentName } = props;

  return (
    <Switch>
      <Route exact path="/" render={() => maHomepage[contentName]} />;
    </Switch>
  );
};
