import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';

import { useTransitionCalculator } from 'src/client/containers/ToggleAnimated/useTransitionCalculator';
import { onEnterHandler } from 'src/client/helpers/onEnterHandler';

type Props = {
  animationName: string;
  animationDuration: number;
  isExpanded: boolean;
  mountCb?: (mounted: boolean) => void;
};

export const ToggleAnimated = (props: PropsWithChildren<Props>) => {
  const { animationName, animationDuration, children, isExpanded, mountCb } = props;

  const { transitionRef, activeHeight, onExitAnimationEnd } = useTransitionCalculator({
    isExpanded,
    mountCb,
  });
  const expandHeightStyle = {
    '--active-height': activeHeight,
  } as React.CSSProperties;

  return (
    <CSSTransition
      classNames={animationName}
      in={isExpanded}
      mountOnEnter
      onEnter={onEnterHandler}
      onExited={onExitAnimationEnd}
      timeout={animationDuration}
      unmountOnExit
    >
      <ExpandWrapper animationDuration={animationDuration} animationName={animationName} style={expandHeightStyle}>
        <div ref={transitionRef}>{children}</div>
      </ExpandWrapper>
    </CSSTransition>
  );
};

const ExpandWrapper = styled.div<{ animationDuration: number; animationName: string }>`
  transition-property: all;
  transition-duration: ${(props) => props.animationDuration}ms;
  transition-timing-function: ease-in-out;

  // allows the slide down effect to work by clipping the content
  // overflow: hidden doesn't work because it alters the height of the children
  // and we measure them to run the transition
  overflow-y: clip;
  overflow-x: hidden;

  height: 0;
  ${(props) => ` &.${props.animationName}-enter-active, &.${props.animationName}-enter-done {
    height: var(--active-height);
  }`};
`;
