import { take, call, fork, select, put } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { setSettings } from './settings';
import { setSettings as setNewsletterSettings } from './newsletter';
import { User } from 'src/@types/app';
import { UserType, UserTypes } from 'src/shared/constants/userTypes';

const LOAD = 'man-site/auth/LOAD';
const LOAD_SAGA = 'man-site/auth/LOAD_SAGA';
export const LOAD_SUCCESS = 'man-site/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/auth/LOAD_FAIL';
const SET_DISPLAYNAME = 'man-site/auth/SET_DISPLAYNAME';

const endpoint = constructUrl(urls.get.userData);

interface Location {
  country?: string;
  isAC: boolean;
  isEU: boolean;
  state?: string;
}

interface InitialState {
  user?: User;
  userType: UserType;
  location: Location;
  loading?: boolean;
  loaded?: boolean;
  error?: ResponseError;
}

const initialState: InitialState = {
  userType: UserTypes.Unknown,
  location: {
    isAC: true,
    isEU: false,
  },
};

interface ActionLoad {
  type: typeof LOAD;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  result: {
    location: Location;
    initialState: {
      auth: {
        user?: User;
      };
    };
  };
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  error: ResponseError;
}

interface ActionSetDisplayName {
  type: typeof SET_DISPLAYNAME;
  name: string;
}

export default function reducer(
  state = initialState,
  action: ActionLoad | ActionLoadSuccess | ActionLoadFail | ActionSetDisplayName,
): InitialState {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_SUCCESS: {
      const user = action.result.initialState?.auth.user;
      const userType = action.result.initialState?.auth.user?.type ?? UserTypes.Guest;
      const { location } = action.result;
      return {
        ...state,
        loading: false,
        loaded: true,
        user,
        userType,
        location,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case SET_DISPLAYNAME: {
      return {
        ...state,
        user: {
          ...(state.user as User),
          displayname: action.name,
        },
      };
    }
    default:
      return state;
  }
}

export function setDisplayName(name) {
  return {
    type: SET_DISPLAYNAME,
    name,
  };
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function load() {
  return {
    type: LOAD_SAGA,
  };
}

/* SAGAS */
export function* loadGenerator() {
  // console.log('loading auth request');
  const getState = (state) => ({ ...state.auth, siteUUID: state.site.UUID });
  const currentState = yield select(getState);
  if (!currentState.loaded) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint), {
      siteUUID: currentState.siteUUID,
    });
    const { result } = yield call(loadFunc);
    if (result?.initialState) {
      yield put(setSettings(result.initialState.settings));
      yield put(setNewsletterSettings(result.initialState.newsletter));
    }
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    yield take(LOAD_SAGA);
    yield call(loadGenerator);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
