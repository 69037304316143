import React, { PropsWithChildren } from 'react';
import ReactHelmet from 'react-helmet';
import Mustache from 'mustache';
import { useLocation } from 'react-router';
import { useSelector } from 'src/client/redux';

type Props = PropsWithChildren<{
  id: string | string[];
  defaultTitle?: string;
  defaultDescription?: string;
  forceDefaultTitle?: boolean;
  forceDefaultDescription?: boolean;
  variables: Record<string, unknown>;
}>;

export default function Helmet(props: Props) {
  const {
    id,
    variables,
    defaultTitle,
    defaultDescription,
    forceDefaultTitle,
    forceDefaultDescription,
    children,
  } = props;
  const { metaTags } = useSelector((state) => state.app.config);
  const location = useLocation();

  const ids = typeof id === 'string' ? [id] : id;

  const metaTagIndex = metaTags && ids.find((id) => id in metaTags);
  const metaTag = metaTagIndex ? metaTags[metaTagIndex] : undefined;

  let metaTitle = defaultTitle;
  if (!forceDefaultTitle && metaTag?.title !== undefined) {
    metaTitle = metaTag.title;
  }
  let metaDescription = defaultDescription;
  if (!forceDefaultDescription && metaTag?.description !== undefined) {
    metaDescription = metaTag.description;
  }

  if (metaTitle) {
    try {
      metaTitle = Mustache.render(metaTitle, variables);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Cant parse title template');
    }
  }

  if (metaDescription) {
    try {
      metaDescription = Mustache.render(metaDescription, variables);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Cant parse description template');
    }
  }
  const canonicalUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  return (
    <ReactHelmet>
      {metaTitle && <title>{metaTitle}</title>}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDescription && <meta property="og:description" content={metaDescription} />}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {children}
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
    </ReactHelmet>
  );
}
