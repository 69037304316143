export function galleryCoverUrl(
  contentCdnUrl: string,
  item: {
    coverImagePath?: string;
    coverCleanImagePath?: string;
    thumbnailCoverPath: string;
    siteUUID: string;
  },
  clean = false,
  thumbnail = false,
  wide = false,
) {
  // const site = SITES_OBJ[item.siteUUID];
  let coverPath = item.coverImagePath || '';
  if (clean) {
    coverPath = item.coverCleanImagePath || '';
  }
  if (thumbnail) {
    coverPath = item.thumbnailCoverPath;
  }
  if (wide) {
    coverPath = coverPath.replace('t_cover_', 'wide_');
  }

  return `${contentCdnUrl}/${item.siteUUID}${coverPath}`;
}
